@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Roboto&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slim-select/1.27.0/slimselect.min.css');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;500;600;700;900&family=Rubik+Dirt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');


@import "partials/vars";
@import "partials/buttons";
@import "partials/forms";
@import "partials/typography";
@import "partials/helpers";
@import "partials/table";
@import "partials/misc";
@import "partials/dropdown";
@import "partials/tabs";
@import "partials/responsive";
@import "partials/modal";
@import "partials/card";

*{
	box-sizing: border-box;
}
html{
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}
body{
	font-size: 16px;
	font-family: 'Didact Gothic', sans-serif;
	display: flex;
	flex-direction: column;
	height: 100%;
	background: var(--background);
	color: var(--textColor);
	width: 100%;
	overflow: hidden;
	overflow-y: auto;
	padding: 0rem;
	margin: 0rem;
	font-weight: normal;
}
#root{
	height: 100%;
}
.App{
	height: 100%;
}
span{
	display: inline-block;
}
a{
	text-decoration: none;
}

.page{
	width: 100%;
	min-height: 100%;
	background-size: 100%;
	background-size: cover;
	background-repeat: no-repeat;

	.page__inner{
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 100%;
		text-align: center;
	}
	.page__main{
		width: 100%;
		height: 100%;
		position: relative;
		position: absolute;
		margin-left: auto;
		margin-right: auto;
		top: 0;
		left: 0;
		right: 0;
		z-index: 10;
		background: var(--background);
		padding-top: 50px;

		.page__main-content{
			width: 95%;
			max-width: 100%;
			margin: auto;
			padding: 20px;
			padding-top: 35px;

			@media only screen and (max-width: 490px) {
				padding: 5px;
			}
		}

		.page__main-menu{
			width: 100%;
			display: flex;
			flex-direction: row;
			padding: 10px;
			position: fixed;
			top: 0;
			width: 95%;
			max-width: 100%;
			margin:auto;
			z-index: 99;
			background-color: var(--background);

			.page__main-logo{
				flex-basis: 300px;
			}
			.page__main-items{
				margin-left: auto;

				.page__main-item{
					display: inline-block;
					vertical-align: middle;
					margin: 5px;
					padding: 10px 20px;
					cursor: pointer;
					border-top: 2px solid transparent;
					border-bottom: 2px solid transparent;

					&:hover{
						border-top: 2px solid var(--primary);
						border-bottom: 2px solid var(--primary);
						background: RGBA(var(--primary-rgb) / 10%);
					}
				}
				@media only screen and (max-width: 810px) {
					display: none;
				}
			}
			.page__main-mobileMenu{
				display: none;
				@media only screen and (max-width: 810px) {
					display: inline-block;
					margin-left: auto;
				}

			}
			@media only screen and (max-width: 590px) {
				padding: 3px;
			}
		}
		.left{
			flex-basis: 50%;
			@media only screen and (max-width: 1115px) {
				flex-basis: 100%;
				text-align: center;

				.landing__card{
					width: 120px;
					height: 150px;
					
					.landing__card-text{
						font-size: 16px;
					}
					img{
						width: 70px;
						height: 70px;
					}
				}
			}
			@media only screen and (max-width: 590px) {
				.landing__card {
					width: 80px;
					height: 120px;
			
					.landing__card-text {
						display: none;
					}
			
					img {
						width: 70px;
						height: 70px;
					}
				}
			}
		}
		.right{
			flex-basis: 50%;
			@media only screen and (max-width: 1115px) {
				flex-basis: 100%;
			}
		}
		.page__main-container{
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			position: relative;

			.page__main-title{
				font-family: 'Rubik Dirt', 'Didact Gothic', cursive;
				font-size: 4rem;
				margin-top: 0px;
				@media only screen and (max-width: 1400px) {
					font-size: 3.5rem;
				}
				@media only screen and (max-width: 1100px) {
					margin-top: 170px;
				}
				@media only screen and (max-width: 600px) {
					font-size: 27px;
				}
			}
			.page__main-typed{
				font-size: 3.5rem;
				font-weight: bold;
				background-color: #f3ec78;
				background-image: linear-gradient(45deg, var(--primary), var(--danger));
				background-size: 100%;
				-webkit-background-clip: text;
				-moz-background-clip: text;
				-webkit-text-fill-color: transparent;
				-moz-text-fill-color: transparent;
				@media only screen and (max-width: 1400px) {
					font-size: 3.5rem;
				}
				@media only screen and (max-width: 600px) {
					font-size: 27px;
				}
			}
			.page__main-ul{
				list-style: none;

				#arrow {
					border-right:2px solid black;
					border-bottom:2px solid black;
					width:10px;
					height:10px;
					transform: rotate(-45deg);
					margin-top:40px;
				}



				li {
					position: relative;
					padding-bottom: 10px;
				}

				list-style: none;

				li:before{
				   content: '';
				   position: absolute;
				   border-right:2px solid var(--primary);
				   border-bottom:2px solid var(--primary);
				   width:10px;
				   height:10px;
				   top: calc(50% - 4px);
				   left: -20px;
				   transform: translateY(-50%) rotate(-45deg);
				}



				@media only screen and (max-width: 1400px) {
					font-size: 23px;
					line-height: 25px;
				}
				@media only screen and (max-width: 1100px) {
					padding-bottom: 70px;
				}
				@media only screen and (max-width: 600px) {
					padding-bottom: 0px;
					font-size: 16px;
				}
			}
		}
		.btn__main{
			width: 400px;
			max-width: 100%;
			font-size: 30px;
			font-weight: bold;
			border-radius: 30px;
			margin: 80px 0px;
			background-image: linear-gradient(45deg, var(--danger), var(--primary));
			background-size: 100%;
			@media only screen and (max-width: 1400px) {
				margin: 40px 0px;
			}
			@media only screen and (max-width: 1115px) {
				margin: auto;
			}
		}
		.main__floater{
			width: 110px;
			height: 110px;
			border-radius: 50%;
			background: #fff;
			position: absolute;


			.main__floater-text{
				background: var(--light);
				padding: 5px 16px;
				border-radius: var(--radius);
				position: absolute;
				bottom: -15px;
				left: 50%;
    			transform: translate(-50%, 0);
			}
			img{
				width: 102px;
				height: 102px;
				border-radius: 50%;
			}
		}
		.main__floater1{
			left: 0;
			top: 0;
			border: 4px solid var(--danger);
		}
		.main__floater2{
			right: 0;
			top: 60px;
			border: 4px solid var(--primary);
		}
		.main__floater3{
			right: 0;
			top: 60px;
			border: 4px solid var(--primary);
			display: none;
		}
		.main__floater4{
			right: 25%;
			bottom: 30px;
			border: 4px solid var(--success);
			@media only screen and (max-width: 1400px) {
				right: 3%;
				bottom: 100px;
			}
			@media only screen and (max-width: 600px) {
				display: none;
			}
		}
		.page__main-features{
			background: RGBA(var(--primary-rgb) / 30%);
			padding: 6px;
			border-radius: 10px;
			margin: auto;
			margin-top: 20px;
			width: 450px;

			@media only screen and (max-width: 1400px) {
				width: 95% !important;
			}
			@media only screen and (max-width: 790px) {
				max-height: 60vh;
				overflow: hidden;
			}
		}
		.page__main-featuresCollapsibleButton{
			display: none;

			@media only screen and (max-width: 790px) {
				display: inline-block;
			}
		}
		.page__main-featuresCollapsible{
			@media only screen and (max-width: 790px) {
				max-height: unset !important;
			}
		}
		.no__radius{
			border-radius: 0px;
		}
	}
}
.section__title{
	font-size: 25px;
	padding: 10px;
	border-left: 3px solid var(--primary);
	margin: 15px 0px;
	font-weight: bold;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.mobile__menu{
	width: 400px;
	position: fixed;
	right: 0;
	top: 0;
	max-width: 100%;
	background: var(--light);
	z-index: 99;
	height: 100%;
	padding: 15px;
	text-align: left;

	.item{
		margin: 5px;
		padding: 10px 20px;
		cursor: pointer;
		font-size: 30px;
		border-top: 2px solid transparent;
		border-bottom: 2px solid transparent;

		&:hover{
			border-top: 2px solid var(--primary);
			border-bottom: 2px solid var(--primary);
			background: RGBA(var(--primary-rgb) / 10%);
		}
	}
}
.logo__o{
	/* width: 30px;
	height: 35px;

	color: var(--primary);
	padding-top: -5px;
	padding-bottom: 5px;
	text-align: center;
	vertical-align: middle;
	margin-left: 4px;
	margin-right: 4px; */

}
.section__header{
	height: 200px;
	text-align: center;
	font-size: 40px;
	font-weight: bold;
	margin-top: 70px;
	background: var(--primary);
	background: url('../images/patternpad.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-transform: uppercase;
	border-radius: 10px;
	border: 4px solid var(--danger);
}
.logo {
	width: 250px;
	height: 70px;
	background: var(--logo);
	background-repeat: no-repeat;
	background-size: contain;
}

.logo__white {
	width: 170px;
	height: 50px;
	background: var(--logoWhite);
	background-repeat: no-repeat;
	background-size: contain;
}

.logo__dark {
	width: 170px;
	height: 50px;
	background: var(--logoBlack);
	background-repeat: no-repeat;
	background-size: contain;
}
.logo__footer{
	width: 100px;
	height: 30px;
	margin-top: 4px;
	margin-left: 10px;
}
@media only screen and (max-width: 700px) {
	h1{
		font-size: 23px;
	}
}
.page__centered{
	width: 1500px !important;
	max-width: 100%;
	margin: auto;
}
.landing__card-active{
	filter: grayscale(100%) drop-shadow(0px 20px 50px rgba(159, 139, 234, 0.8));
}