.contact{
	margin-top: 50px;
	padding-top: 50px;
	background: var(--light);
	padding: 10px;

	.contact__wrapper{
		position: relative;
		padding-bottom: 100px;
		text-align: center;

		padding: 20px;

		.hero__underline{
			width: 150px;
			fill: var(--primary) !important;
		}
	}
}