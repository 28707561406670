@import url('../partials/vars.scss');

$midnight: #645CAA;
$clouds: #ecf0f1;

input {
	position: absolute;
	opacity: 0;
	z-index: -1;
}

/* Accordion styles */
.faqs {
	width: 1200px;
	margin: auto;
	max-width: 100%;
}

.tab {
	width: 100%;
	color: white;
	overflow: hidden;

	&-label {
		display: flex;
		justify-content: space-between;
		padding: 1em;
		background: $midnight;
		font-weight: bold;
		font-size: 20px;
		cursor: pointer;

		/* Icon */
		&:hover {
			background: darken($midnight, 10%);
		}

		&::after {
			content: "\276F";
			width: 1em;
			height: 1em;
			text-align: center;
			transition: all .35s;
		}
	}

	&-content {
		max-height: 0;
		padding: 0 1em;
		color: $midnight;
		transition: all .35s;
	}

	&-close {
		display: flex;
		justify-content: flex-end;
		padding: 1em;
		font-size: 1em;
		background: $midnight;
		cursor: pointer;

		&:hover {
			background: darken($midnight, 10%);
		}
	}
}

// :checked
input:checked {
	+.tab-label {
		background: darken($midnight, 10%);

		&::after {
			transform: rotate(90deg);
		}
	}

	~.tab-content {
		max-height: 100vh;
		padding: 1em;
	}
}