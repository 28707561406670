.how_to{
	margin-top: 50px;
	padding-top: 50px;
	background: var(--light);
	padding: 10px;

	.how_to__wrapper{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.how_to__left{
			flex-basis: 100%;

			.how__to-website{
				width: 70%;
				margin: auto;
				display: flex;
				flex-direction: column;
				padding: 10px;
				background-color: var(--secondary);
				border: 1px solid RGBA(var(--textColor-rgb) / 25%);
				text-align: left;

				.how__to-websiteHeader{
					display: flex;
					flex-direction: row;
				}
				
				.how__to-card{
					height: 400px;
					display: flex;
					flex-direction: row;
					place-items: center;
					padding: 15px;
					position: relative;
					margin-top: 60px;

					.how__to-card-placeholder{
						height: 400px;
						width: 300px;
						max-width: 49%;
						border: 3px dashed RGBA(var(--textColor-rgb) / 15%);
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
					}
				}
				.how__to-card-primary{
					border: 3px solid var(--primary);
					height: 400px;
					width: 300px;
					position: absolute;
					right: -15px;
					top: -95px;
					transform: rotate(25deg);
				}
				@media only screen and (max-width: 1200px) {
					width: 90%;
					.arrow__left {
						display: none;
					}
				
					.how__to-card {
						height: 800px;
				
						.how__to-card-placeholder {
							margin-top: 300px;
							flex-basis: 100% !important;
							max-width: 100%;
							width: 100% !important;
						}
					}
				}
				@media only screen and (max-width: 700px) {
					width: 100%;

					.article__card{
						display: flex;
						flex-direction: column !important;
					}
					.how__to-card-primary{
						width: 200px;
						height: 300px;
						top: 0px;
						right: 10px;
					}
				}
			}
		}
		.how_to__right{
			flex-basis: 50%;
		}
	}
}