.pricing{
	margin-top: 50px;
	padding: 10px;
	padding-top: 50px;

	.pricing__wrapper{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.pricing__wrapper-left{
			flex-basis: 50%;

			.pricing__item{
				width: 100%;
				cursor: pointer;
				text-align: left;
				padding: 5px;
				border-radius: var(--radius);
				background: RGBA(var(--primary-rgb) / 30%);
				margin: 10px;

				.pricing__item-inner{
					padding: 15px;
					border-radius: var(--radius);
					background: var(--secondary);
					display: flex;
					flex-direction: row;
				}
			}
			.unchecked{
				display: inline-block;
			}
			.checked{
				display: none;
			}
			.pricing__itemActive{
				background: var(--primary);
				color: var(--primary);

				.unchecked{
					display: none;
				}
				.checked{
					display: inline-block;
				}
			}
			@media only screen and (max-width: 1100px) {
				flex-basis: 100%;
				order: 2; margin-top: 40px;
			}
		}
		.pricing__wrapper-right{
			flex-basis: 50%;
			text-align: center;
			@media only screen and (max-width: 1100px) {
				flex-basis: 100%;
				order: 1;
			}
		}
		.pricing__list{
			width: 300px;
			max-width: 100%;
			margin: auto;
			text-align: left;
		}

	}
	.pricing__start-price{
		margin-top: -10px;
		display: inline-block;
	}
}