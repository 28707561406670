.promo_video {
	display: flex;
	justify-content: center;
	margin-top: 50px;

	video {
		width: 100%;
		max-width: 1110px; 
		border-radius: 5px;
	}
}
