@import '../partials/vars.scss';


.landing{
	.landing__input{
		width: 900px;
		max-width: 100%;
		margin: auto;
		display: flex;
		flex-direction: row;
		padding: 3px 20px;
		border-radius: 50px;
		background: var(--primary);
		justify-content: center;
		color: var(--secondaryTextColor);
		margin-top: 25px;

		.landing__input-header{
			padding-top: 10px;
			margin-right: 10px;
		}
		input{
			flex-basis: 40%;
			border-radius: 30px;
			height: 55px;
			background-color: var(--background);

			&::placeholder{
				font-size: 18px;
				color: RGBA(var(--textColor-rgb) / 90%);
			}
		}

	}
	.landing__card-container{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
		position: relative;
		margin-top: -20px;
		margin-bottom: 30px;
		
		.landing__card {
			position: relative;
			margin: 0px 10px;
			overflow: hidden;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			height: 270px;
			width: 250px;
			cursor: pointer;
			font-weight: bold;


			img{
				height: 190px;
			}
			.landing__card-text{
				position: absolute;
				bottom: 0px;
				width: 220px;
				text-align: center;
				left: 50%;
				transform: translate(-50%, 0);
				font-size: 25px;
				color: var(--primary);
			}
			&:hover{
				filter: drop-shadow(0px 20px 50px rgba(159, 139, 234, 0.5));
			}
		}
		
	}
}