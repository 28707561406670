.footer{
	padding-top: 50px;
	background: RGBA(var(--primary-rgb) / 10%);
	padding: 20px;
    display: flex;
    flex-direction: row;
    color: var(--secondaryTextColor);
    justify-content: center;

    .footer__item{
        margin-left: 10px;
        margin-right: 10px;
    }
    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
    
}