.login{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login__form{
        background: var(--light);
        padding: 15px;
        border-radius: 10px;
        width: 450px;
        max-width: 100%;

        .login__form-header{
            text-align: center;
        }
        .login__title{
            padding: 10px;
            border-left: 2px solid var(--primary);
            margin: 20px 0px !important;
            display: block;
        }
    }
    .dashed__hr{
        border-top: 2px dashed var(--primary);
    }
}